<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="singin">
		<div class="singin-apply">
			<div @click="toPersional" class="singin-apply-item">
				<div class="apply-item-left">
					<img v-if="singinDetail.userData.studentAvatar" class="apply-item-avatar"
						:src="singinDetail.userData.studentAvatar" alt="">
					<img v-else class="apply-item-avatar" src="@/assets/images/mobile/common/avatar.png" alt="">
					<div class="apply-item-user">
						<div class="item-user-inner">
							<span class="item-user-name">{{singinDetail.userData.name}}</span>
							<span class="item-user-num">{{singinDetail.userData.stuNumber}}</span>
						</div>
						<span class="item-user-class">{{singinDetail.userData.stClass}}</span>
					</div>
				</div>
				<div class="apply-item-right">
					<img src="@/assets/images/mobile/common/arrow-right.png" alt="" class="apply-item-edit">
				</div>
			</div>
		</div>
		<jobList :jobList="singinDetail.jobData" :importArea="'singin'" :hasMinHeight="false"/>
		<div class="singin-box">
			<div @click="singFn(1)" class="singin-btn" v-if="singinDetail.userData.state == 0">
				立即签到
			</div>
			<div class="singin-btn singin-btn-success" v-if="singinDetail.userData.state == 1&&isSingin">
				签到成功
			</div>
			<div @click="singFn(0)" class="singin-btn" v-if="singinDetail.userData.state == 1&&!isSingin">
				立即签退
			</div>
			<div class="singin-btn singin-btn-success" v-if="singinDetail.userData.state == 2">
				签退成功
			</div>
			<span
				class="singin-text">签到时间：{{singinDetail.userData.state == 0?'暂未签到':singinDetail.userData.state <= 2?singinDetail.userData.starTime: ''}}</span>
			<span
				class="singin-text">签退时间：{{singinDetail.userData.state <= 1?'暂未签退':singinDetail.userData.endTime}}</span>
		</div>
	</div>
</template>
<script>
	import jobList from "../components/jobList.vue"
	import {
		scanDetailMb,
		singInOutMb,
	} from "@/api/mobile/mine"
	import {
		Toast
	} from 'vant';
	import storage from 'store'
	export default {
		components: {
			jobList
		},
		data() {
			return {
				// jobData: [{
				// 		title: "校内固定岗位标题内容",
				// 		status: 1,
				// 		type: '校内固定',
				// 		time: "2022-06-21 12:00 至 16:00",
				// 		area: "国家大学科技园B幢610室"
				// 	},
				// ],
				singinDetail: {},
				scanResult: "",
				isSingin: false,
			}
		},
		mounted() {
			// storage.get('accessToken')
			this.scanResult = this.$route.query.scanResult
			this.getScanDetail()
		},
		methods: {
			// 获取二维码详情
			getScanDetail() {
				let params = {
					qrCode: this.scanResult
				}
				this.singinDetail = {
					userData: {},
					jobData: []
				}
				scanDetailMb(params).then((res) => {
					if (res.code == 0) {
						// if(!res.data.postQrCode.studentAvatar) {
						// 	this.$set(res.data.postQrCode, "studentAvatar", "")
						// }
						this.singinDetail.userData = res.data.postQrCode
						res.data.postDetailVO.state = res.data.postQrCode.state
						this.singinDetail.jobData = [res.data.postDetailVO]
					}
				})
			},
			// 签到/签退
			singFn(num) {
				let params = {
					postId: this.singinDetail.userData.postId,
					studentId: this.singinDetail.userData.studentId,
					userName: this.singinDetail.userData.studentName,
					signIn: undefined
				}
				if (num == 0) {
					params.signIn = false
				} else {
					params.signIn = true
				}
				singInOutMb(params).then(res => {
					if (res.code == 0) {
						this.isSingin = true
						if (num == 0) {
							Toast.success({
								message: "签退成功"
							})
						} else {
							Toast.success({
								message: "签到成功"
							})
						}
						this.getScanDetail()
					} else {
						this.isSingin = false
					}
				})
			},
			// 个人主页
			toPersional() {
				this.$router.push({
					path: `/mb/mine/personal?visitType=singin&&studentId=${this.singinDetail.userData.studentId}`
				})
			},
		},
	}
</script>
<style lang="scss" scoped>
	.singin {
		display: flex;
		flex-direction: column;
		padding: 0 0.28rem;

		.singin-apply {
			display: flex;
			flex-direction: column;
			margin: 0.16rem 0;

			.singin-apply-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				background: #FFFFFF;
				padding: 0.3rem 0.3rem;
				box-sizing: border-box;

				.apply-item-left {
					display: flex;
					align-items: center;

					.apply-item-avatar {
						width: 0.9rem;
						height: 0.9rem;
						border-radius: 50%;
						margin-right: 0.22rem;
					}

					.apply-item-user {
						display: flex;
						flex-direction: column;

						.item-user-inner {
							display: flex;
							align-items: center;

							.item-user-name {
								font-size: 0.32rem;
								font-family: PingFang SC;
								font-weight: bold;
								line-height: 0.44rem;
								color: #303133;
							}

							.item-user-num {
								font-size: 0.28rem;
								font-family: PingFang SC;
								font-weight: 400;
								line-height: 0.44rem;
								color: #606266;
							}
						}

						.item-user-class {
							font-size: 0.24rem;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 0.34rem;
							color: #909399;
							margin-top: 0.1rem;
						}
					}
				}

				.apply-item-right {
					display: flex;
					flex-direction: column;
					align-items: flex-end;

					.apply-item-hour {
						font-size: 0.28rem;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 0.4rem;
						color: #606266;
					}

					.apply-item-edit {
						width: 0.28rem;
						height: 0.28rem;
						margin-top: 0.14rem;
					}
				}
			}
		}

		.singin-box {
			width: 6.94rem;
			height: 4.48rem;
			background: #FFFFFF;
			border-radius: 0.16rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.singin-btn {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 2rem;
				height: 2rem;
				background: #4392FA;
				border-radius: 50%;
				font-size: 0.32rem;
				font-family: PingFang SC;
				font-weight: bold;
				color: #FFFFFF;
				margin-bottom: 0.32rem;
			}

			.singin-btn-success {
				background: #15D2B7;
			}

			.singin-text {
				font-size: 0.28rem;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 0.4rem;
				color: #606266;
				margin-top: 0.08rem;
			}
		}
	}
</style>
